body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: "#FFFFFF"
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-pdf__Page__textContent {
  border: 1px solid rgb(211, 211, 211);
  box-shadow: 10px 10px 50px 1px rgb(235, 235, 235);
  border-radius: 5px;
}

/* Hide scrollbar in div and span */
body,
div,
span,
ul {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

div::-webkit-scrollbar,
span::-webkit-scrollbar,
ul::-webkit-scrollbar {
  display: none;
}

/* LazyLoad component style */
.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.is-visible {
  opacity: 1;
}

#root {
  overflow-y: auto;
  scrollbar-width: none;
}